import { parse } from '@src/helpers/query';
import { isCrmContextPage, isMegafonPages } from '@src/utils/routing';

const querySchema = { scheme: { calculator: Boolean }, defaultValues: { calculator: true } };
/**
 * Отключает показ калькулятора ПС и калькулятора на странице
 * @description
 * работает для страниц
 *
 * контекстные витрины мегафона: [ /zaimy/1f_good/ и /zaimy/1f_fix/]
 *
 * контекстная витрина CRM: [/zaimy/predlozheniya-iz-crm/]
 * @example
 * ```
 * const {asPath}=useRouter();
 * const isHidden=isHiddenCalculatorByQuery(asPath);
 *
 * ```
 * @param asPath
 * @returns {boolean}
 */
export function isHiddenCalculatorByQuery(asPath: string): boolean {
  const [pathname, query] = asPath.split('?');

  if (isMegafonPages(pathname) || isCrmContextPage(pathname)) {
    try {
      const queryObject = parse({ query, ...querySchema });
      return queryObject?.calculator === false;
    } catch (err) {
      return false;
    }
  }
  return false;
}
